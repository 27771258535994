import { createAsyncAction } from "typesafe-actions";
import type { ThunkActionCreator } from "..";
import type {
  User,
  LoginProvidersInner,
  LoginApiOidcLoginRequest,
} from "@eai-toolkit/client";
import {
  MeApi,
  LoginApi,
  LoginApiAxiosParamCreator,
} from "@eai-toolkit/client";
import { assertIsError } from "../../AppManager/utils";

import { apiConfiguration, axiosInstance } from "../../helpers/api";
import Config from "../../config";
import type { AxiosError } from "axios";
import { tracker } from "@openreplay/tracker";

export const actions = {
  loggedInUser: createAsyncAction(
    "login/LOGIN_REQUEST",
    "api/ME_SUCCESS",
    "api/ME_FAILURE",
  )<void, User, Error>(),
  getTerms: createAsyncAction(
    "api/GET_TERMS_REQUEST",
    "api/GET_TERMS_SUCCESS",
    "api/GET_TERMS_FAILURE",
  )<void, string, Error>(),
  agreeToTerms: createAsyncAction(
    "api/SET_TERMS_REQUEST",
    "api/SET_TERMS_SUCCESS",
    "api/SET_TERMS_FAILURE",
  )<void, string, Error>(),
  oidcLogin: createAsyncAction(
    "login/oidc/auth/OIDC_REQUEST",
    "login/oidc/auth/OIDC_SUCCESS",
    "login/oidc/auth/OIDC_FAILURE",
  )<void, string, AxiosError>(),
  getProviders: createAsyncAction(
    "providers/GET_REQUEST",
    "providers/GET_SUCCESS",
    "providers/GET_FAILURE",
  )<void, LoginProvidersInner[], AxiosError>(),
};

const meApi = new MeApi(apiConfiguration, "", axiosInstance);
const loginApi = new LoginApi(apiConfiguration, "", axiosInstance);
const loginApiParamsCreator = LoginApiAxiosParamCreator(apiConfiguration);

export const oidcLogin: ThunkActionCreator<LoginApiOidcLoginRequest> =
  (oidcLoginRequest) => async (dispatch) => {
    dispatch(actions.oidcLogin.request());
    try {
      const params = await loginApiParamsCreator.oidcLogin(
        oidcLoginRequest.providerId,
        oidcLoginRequest.loginChallenge,
      );
      // Redirect the user to the new URL
      window.location.href = params.url;
    } catch (err) {
      dispatch(actions.oidcLogin.failure(err as AxiosError));
    }
  };

export const getProvidersList: ThunkActionCreator<void> =
  () => async (dispatch) => {
    dispatch(actions.getProviders.request());

    try {
      const { data } = await loginApi.getProviders();
      dispatch(actions.getProviders.success(data));
    } catch (err) {
      assertIsError(err);
      console.error(err);
      dispatch(actions.getProviders.failure(err as AxiosError));
    }
  };

enum Environment {
  Prod = "prod",
  Dev = "dev",
  LocalDev = "localdev",
}

const environmentConfig: Record<Environment, { projectKey: string }> = {
  [Environment.Prod]: { projectKey: "mbXhw9WHocYTCAE7lj7E" },
  [Environment.Dev]: { projectKey: "1JvxPtueyn15u2DwNjpH" },
  [Environment.LocalDev]: { projectKey: "OnGhsF7QTfrB4xRaoXV7" },
};

const getEnvironment = (): Environment => {
  const hostname = window.location.href;
  if (hostname.includes("console-dev")) return Environment.Dev;
  if (hostname.includes("console")) return Environment.Prod;
  return Environment.LocalDev;
};

export const loggedInUser: ThunkActionCreator<void> =
  () => async (dispatch) => {
    dispatch(actions.loggedInUser.request());

    try {
      const { data } = await meApi.v1MeGet({});

      const environment = getEnvironment();
      const { projectKey } = environmentConfig[environment] || {};
      if (!projectKey) {
        console.error("Unknown environment.");
      }

      // Once the user is logged in, we start the OpenReplay tracker
      tracker.configure({
        projectKey,
        ingestPoint: "https://openreplay.console.elementai.com/ingest",
        __DISABLE_SECURE_MODE: environment === Environment.LocalDev, // disable secure mode for local development only (which is not on https)
        // If we'd like to obscure emails, numbers, or inputs, we can set these options
        // Note that password inputs will already obscured by default
        obscureTextEmails: false,
        obscureTextNumbers: false,
        obscureInputEmails: false,
        obscureInputNumbers: false,
        obscureInputDates: false,
        defaultInputMode: 0, // 0=plain, 1=obscured, 2=ignored
      });
      tracker.start({
        userID: data.mail || data.name || data.id,
        metadata: {
          organization: data.organization || "",
          id: data.id || "",
        },
      });
      dispatch(actions.loggedInUser.success(data as User));
    } catch (err) {
      dispatch(actions.loggedInUser.failure(err as AxiosError));
      if (!Config.isDev) {
        // this is extremely annoying during local development so let's skip it..
        // no session cookie? redirect to console.elementai.com/login
        window.location.href = "/login";
      }
    }
  };

export const getTerms: ThunkActionCreator<void> = () => async (dispatch) => {
  dispatch(actions.getTerms.request());

  try {
    const { data } = await meApi.v1MeTermsGet();
    dispatch(actions.getTerms.success(data));
  } catch (err) {
    assertIsError(err);
    console.error(err);
    dispatch(actions.getTerms.failure(err));
  }
};

export type AgreeToTermsType = (params: void) => Promise<void>;

export const agreeToTerms: ThunkActionCreator<void> =
  () => async (dispatch) => {
    dispatch(actions.agreeToTerms.request());

    try {
      const { data } = await meApi.v1MeTermsPut();
      dispatch(actions.agreeToTerms.success(data));
    } catch (err) {
      assertIsError(err);
      console.error(err);
      dispatch(actions.agreeToTerms.failure(err));
    }
  };
